var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selectbox-root",class:{ error: _vm.hasError, focused: _vm.isFocused, disabled: _vm.disabled },attrs:{"data-cy":_vm.dataCy}},[_c('label',[_vm._v(_vm._s(_vm.name))]),_c('v-select',_vm._b({attrs:{"value":_vm.value,"options":_vm.options,"disabled":_vm.disabled,"label":_vm.label,"name":_vm.name,"loading":_vm.loading,"dropdown-should-open":function () { return _vm.focused; }},on:{"search":function($event){return _vm.$emit('search', $event)},"input":function($event){return _vm.$emit('input', $event)},"search:focus":_vm.onFocus,"search:blur":_vm.onBlur,"close":_vm.onClose,"open":_vm.onOpen},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_c('span',{staticClass:"search-info"},[_c('em',[_vm._v(_vm._s(search))]),_vm._v(" "+_vm._s(_vm.$t("validations.notFound")))])]:_c('em',{staticClass:"opacity"},[_c('span',{staticClass:"search-info"},[_vm._v(_vm._s(_vm.$t("validations.noOptions")))])])]}},(_vm.reportType)?{key:"option",fn:function(option){return [_c('span',{staticClass:"flex align-center"},[_c('report-type',{attrs:{"type":option.value}}),_vm._v(" "+_vm._s(option.name)+" ")],1)]}}:null,(_vm.formatOption)?{key:"selected-option",fn:function(ref){
var fullName = ref.fullName;
return [_vm._v(" "+_vm._s(fullName)+" ")]}}:(_vm.reportType)?{key:"selected-option",fn:function(ref){
var name = ref.name;
var value = ref.value;
return [_c('span',{staticClass:"flex align-center"},[_c('report-type',{attrs:{"type":value}}),_vm._v(" "+_vm._s(name)+" ")],1)]}}:null,(!_vm.isLastPage && _vm.isAssetsPicker)?{key:"list-footer",fn:function(){return [_c('li',{ref:"load",staticClass:"spinner-li"},[_c('b-spinner',{attrs:{"small":""}})],1)]},proxy:true}:null],null,true)},'v-select',_vm.$attrs,false)),_c('p',{staticClass:"inputHelper",class:{ errorMessage: _vm.hasError }},[_vm._v(" "+_vm._s(_vm.helperText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }