var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav-bar'),_c('div',{attrs:{"id":"wrapper"}},[_c('b-container',{attrs:{"fluid":"","id":"main-content"}},[_c('b-card',[_c('b-row',{staticClass:"col-auto order-2 row",attrs:{"cols":"1"}},[_c('h2',{staticClass:"pl-h1"},[_vm._v(_vm._s(_vm.$t("tax.title")))])]),_c('b-row',{staticClass:"col-auto order-2 row",attrs:{"cols":"1"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tax.description'))}})]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationObserver',{attrs:{"id":"tax-form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.yearSelect")))]),_c('span',{staticClass:"mandatory"},[_vm._v(" * ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('select-box',{attrs:{"options":_vm.availableYears,"label":"label","clearable":false,"helperText":errors[0],"hasError":failed},model:{value:(_vm.report_year),callback:function ($$v) {_vm.report_year=$$v},expression:"report_year"}}),_c('b-alert',{attrs:{"variant":"danger","show":failed}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.realizedFile")))]),_c('span',{staticClass:"mandatory"},[_vm._v(" * ")]),_c('ValidationProvider',{attrs:{"rules":"size:2000|ext:pdf|required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var failed = ref.failed;
return [_c('b-form-file',{staticStyle:{"margin-bottom":"10px"},attrs:{"no-drop":"","placeholder":'Realized Summary statement',"state":failed? false : null,"accept":"text/csv,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":validate},model:{value:(_vm.baseReport),callback:function ($$v) {_vm.baseReport=$$v},expression:"baseReport"}}),_c('b-alert',{attrs:{"variant":"danger","show":failed}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.accountsFile")))]),_c('span',{staticClass:"mandatory"},[_vm._v(" * ")]),_c('ValidationProvider',{attrs:{"rules":"size:2000|ext:csv|required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var failed = ref.failed;
return [_c('b-form-file',{staticStyle:{"margin-bottom":"10px"},attrs:{"aria-required":"true","no-drop":"","placeholder":'Portfolio Analyst CSV statement',"state":failed? false : null,"accept":"text/csv,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":validate},model:{value:(_vm.summaryReport),callback:function ($$v) {_vm.summaryReport=$$v},expression:"summaryReport"}}),_c('b-alert',{attrs:{"variant":"danger","show":failed}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),(_vm.hasPredefinedOptions)?_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.additionalData")))]),_c('multi-switch',{staticClass:"custom-form-item",staticStyle:{"margin-bottom":"10px"},attrs:{"options":_vm.multiSwitchOptions},model:{value:(_vm.multiSwitchValue),callback:function ($$v) {_vm.multiSwitchValue=$$v},expression:"multiSwitchValue"}})],1):_vm._e(),(_vm.isLastYearValuesDisabled)?_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.userTaxOptions")))]),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('select-box',{attrs:{"options":_vm.predefinedOptions,"label":"label","clearable":false,"helperText":errors[0],"hasError":failed},model:{value:(_vm.selectedPredefinedSettings),callback:function ($$v) {_vm.selectedPredefinedSettings=$$v},expression:"selectedPredefinedSettings"}}),_c('b-alert',{attrs:{"variant":"danger","show":failed}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.lastYearShorts")))]),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('b-input-group',{attrs:{"append":_vm.taxCurrency}},[_c('b-form-input',{staticClass:"custom-form-item",staticStyle:{"margin-bottom":"10px"},attrs:{"disabled":_vm.isLastYearValuesDisabled,"type":"number","step":"0.01","min":"0"},model:{value:(_vm.shorts),callback:function ($$v) {_vm.shorts=$$v},expression:"shorts"}})],1),_c('b-alert',{attrs:{"variant":"danger","show":failed}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.lastYearLongs")))]),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var failed = ref.failed;
return [_c('b-input-group',{attrs:{"append":_vm.taxCurrency}},[_c('b-form-input',{staticClass:"custom-form-item",staticStyle:{"margin-bottom":"10px"},attrs:{"disabled":_vm.isLastYearValuesDisabled,"type":"number","step":"0.01","min":"0"},model:{value:(_vm.longs),callback:function ($$v) {_vm.longs=$$v},expression:"longs"}}),_c('b-alert',{attrs:{"variant":"danger","show":failed}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"form-item"},[_c('label',[_vm._v(_vm._s(_vm.$t("tax.userNote")))]),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var failed = ref.failed;
return [_c('b-form-input',{staticClass:"custom-form-item",staticStyle:{"margin-bottom":"10px"},attrs:{"type":"text"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('b-alert',{attrs:{"variant":"danger","show":failed}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('br'),_c('div',[_c('b-button',{staticStyle:{"display":"inline"},attrs:{"nativeType":"submit","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("general.buttons.send"))+" ")]),(_vm.pdfURL)?_c('b-button',{staticStyle:{"display":"inline","margin-left":"10px"},attrs:{"download":""},on:{"click":_vm.downloadPdf}},[_vm._v(" "+_vm._s(_vm.$t("general.buttons.download"))+" PDF ")]):_vm._e()],1)])]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"manual"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('tax.manual'))}})])])],1)],1),_c('b-card',[(!_vm.isCalculating && _vm.calculatedReport.length)?_c('b-table',{ref:"rbTable",attrs:{"items":_vm.calculatedReport,"id":"tax-report","fields":_vm.mainFields,"striped":""}}):_vm._e(),(_vm.pdfURL)?_c('div',{staticClass:"pdf-preview"},[_c('pdf',{staticStyle:{"height":"100%"},attrs:{"src":_vm.pdfURL}})],1):_vm._e(),(!_vm.isCalculating && _vm.errors.length)?_c('div',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.$t("tax.cannotRetrieve"))+" "),_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])}),0)]):_vm._e(),(_vm.isCalculating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }