<template>
  <div>
    <nav-bar />
    <div id="wrapper">
      <b-container fluid id="main-content">
        <b-card>
            <b-row cols="1" class="col-auto order-2 row">
              <h2 class="pl-h1">{{ $t("tax.title") }}</h2>
            </b-row>
            <b-row cols="1" class="col-auto order-2 row">
              <div v-html="$t('tax.description')" />
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <ValidationObserver id="tax-form" v-slot="{ handleSubmit }" >
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <div class="form-item">
                      <label>{{$t("tax.yearSelect")}}</label>
                      <span class="mandatory"> * </span>
                      <ValidationProvider v-slot="{ errors, failed }" rules="required">
                        <select-box
                          :options="availableYears"
                          label="label"
                          v-model="report_year"
                          :clearable="false"
                          :helperText="errors[0]"
                          :hasError="failed"
                        />
                        <b-alert variant="danger" :show="failed">
                          {{errors[0]}}
                        </b-alert>
                      </ValidationProvider>
                    </div>
                    <div class="form-item">
                      <label>{{$t("tax.realizedFile")}}</label>
                      <span class="mandatory"> * </span>
                      <ValidationProvider
                          v-slot="{ errors, validate, failed }"
                          rules="size:2000|ext:pdf|required"
                          mode="lazy"
                      >
                        <b-form-file
                            v-model="baseReport"
                            no-drop
                            :placeholder="'Realized Summary statement'"
                            :state="failed? false : null"
                            style="margin-bottom: 10px"
                            accept="text/csv,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="validate"
                        />
                        <b-alert variant="danger" :show="failed">
                          {{errors[0]}}
                        </b-alert>
                      </ValidationProvider>
                    </div>
                    <div class="form-item">
                      <label>{{$t("tax.accountsFile")}}</label>
                      <span class="mandatory"> * </span>
                      <ValidationProvider
                          v-slot="{ errors, validate, failed }"
                          rules="size:2000|ext:csv|required"
                          mode="lazy"
                      >
                        <b-form-file
                          aria-required="true"
                            v-model="summaryReport"
                            no-drop
                            :placeholder="'Portfolio Analyst CSV statement'"
                            :state="failed? false : null"
                            style="margin-bottom: 10px"
                            accept="text/csv,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            @change="validate"
                        />
                        <b-alert variant="danger" :show="failed">
                          {{errors[0]}}
                        </b-alert>
                      </ValidationProvider>
                    </div>
                    <div class="form-item" v-if="hasPredefinedOptions">
                      <label>{{$t("tax.additionalData")}}</label>
                      <multi-switch
                        style="margin-bottom: 10px"
                        v-model="multiSwitchValue"
                        class="custom-form-item"
                        :options="multiSwitchOptions"
                      />
                    </div>
                      <div class="form-item" v-if="isLastYearValuesDisabled">
                        <label>{{$t("tax.userTaxOptions")}}</label>
                        <ValidationProvider v-slot="{ errors, failed }">
                          <select-box
                            :options="predefinedOptions"
                            label="label"
                            v-model="selectedPredefinedSettings"
                            :clearable="false"
                            :helperText="errors[0]"
                            :hasError="failed"
                          />
                          <b-alert variant="danger" :show="failed">
                            {{errors[0]}}
                          </b-alert>
                        </ValidationProvider>
                      </div>
                      <div class="form-item">
                        <label>{{$t("tax.lastYearShorts")}}</label>
                        <ValidationProvider v-slot="{ errors, failed }">
                          <b-input-group :append="taxCurrency">
                            <b-form-input
                                :disabled="isLastYearValuesDisabled"
                                class="custom-form-item"
                                type="number"
                                v-model="shorts"
                                style="margin-bottom: 10px"
                                step="0.01"
                                min="0"
                            />
                          </b-input-group>
                          <b-alert variant="danger" :show="failed">
                            {{errors[0]}}
                          </b-alert>
                        </ValidationProvider>
                      </div>
                      <div class="form-item">
                        <label>{{$t("tax.lastYearLongs")}}</label>
                        <ValidationProvider v-slot="{ errors, validate, failed }">
                          <b-input-group :append="taxCurrency">
                              <b-form-input
                                  :disabled="isLastYearValuesDisabled"
                                  class="custom-form-item"
                                  type="number"
                                  v-model="longs"
                                  style="margin-bottom: 10px"
                                  step="0.01"
                                  min="0"
                              />
                            <b-alert variant="danger" :show="failed">
                              {{errors[0]}}
                            </b-alert>
                        </b-input-group>
                        </ValidationProvider>
                      </div>
                      <div class="form-item">
                        <label>{{$t("tax.userNote")}}</label>
                        <ValidationProvider v-slot="{ errors, validate, failed }">
                          <b-form-input
                              type="text"
                              class="custom-form-item"
                              v-model="note"
                              style="margin-bottom: 10px"
                          />
                          <b-alert variant="danger" :show="failed">
                            {{errors[0]}}
                          </b-alert>
                        </ValidationProvider>
                      </div>
                    <br/>
                    <div >
                      <b-button style="display: inline;"
                          nativeType="submit"
                          type="submit"
                      >
                        {{$t("general.buttons.send")}}
                      </b-button>
                      <b-button v-if="pdfURL" style="display: inline; margin-left: 10px"
                          @click="downloadPdf"
                          download
                      >
                        {{$t("general.buttons.download")}} PDF
                      </b-button>
                    </div>

                  </form>
                </ValidationObserver>
              </b-col>
              <b-col cols="12" sm="6">
                <div class="manual">
                  <div v-html="$t('tax.manual')" />
                </div>
              </b-col>
            </b-row>
        </b-card>
        <b-card>
          <b-table
              v-if="!isCalculating && calculatedReport.length"
              :items="calculatedReport"
              id="tax-report"
              :fields="mainFields"
              ref="rbTable"
              striped
          />
          <div class="pdf-preview" v-if="pdfURL">
            <pdf
                style="height: 100%"
                :src="pdfURL"
            />
          </div>
          <div class='status' v-if="!isCalculating && errors.length">
            {{$t("tax.cannotRetrieve")}}
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </div>
          <b-spinner v-if="isCalculating" small/>
        </b-card>
      </b-container>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters} from "vuex";
import MultiSwitch from "@/components/inputs/MultiSwitch.vue";
import NavBar from "@/components/navs/MainNavigation";
import SelectBox from "@/components/inputs/SelectBox.vue";
import axios from 'axios'
import pdf from 'vue-pdf'

export default {
  components: {
    MultiSwitch, NavBar, pdf, SelectBox 
  },
  data() {
    return {
      errors: [],
      isCalculating: false,
      baseReport: null,
      pdfReport: null,
      summaryReport: null,
      calculatedReport: [],
      report_year: null,
      selectedPredefinedSettings: null,
      shorts: 0,
      longs: 0,
      note: "",
      taxCurrency: "USD",
      multiSwitchValue: {id: "custom"},
      multiSwitchOptions: [{
        id: "custom",
        name: this.$i18n.t("tax.multiSwitchCustom")
      },{
        id: "predefined",
        name: this.$i18n.t("tax.multiSwitchPredefined")
      }],
      mainFields: [
        {
          key:"year",
          label: this.$i18n.t("tax.year")
        },
        {
          key:"cnb_rate",
          label: this.$i18n.t("tax.cnbRate")
        },
        {
          key:"real_result",
          label: this.$i18n.t("tax.realResult")
        },
        {
          key:"theoretical_result_before_taxation",
          label: this.$i18n.t("tax.theoreticalResultBeforeTax")
        },
        {
          key:"result_after_taxation_cnb",
          label: this.$i18n.t("tax.resultAfterTax")
        },
        {
          key:"tax_cnb",
          label: this.$i18n.t("tax.taxCNB")
        },
        {
          key:"tax_ibkr",
          label: this.$i18n.t("tax.taxIBKR")
        },
      ],
    }
  },
  methods: {
    ...mapActions("stockpickingLabStore", ["fetchAvailableTaxYears", "fetchTaxHistory"]),
    onSubmit() {
      this.isCalculating = true;
      this.calculatedReport = [];
      this.pdfReport = null;
      this.taxesExtractionTaskId = null;
      this.errors = [];

      let formData = new FormData();

      let handleErr = res => {
        this.isCalculating = false;
        if (res.response) {
          if (res.response.data.toString() === '[object ArrayBuffer]') {
            this.errors.push(Buffer.from(res.response.data).toString('utf8'));
          } else {
            this.errors.push(res.response.data);
          }
        } else {
          this.errors.push("Network error");
        }
      }

      formData.append('year', this.report_year);
      formData.append('realizedSummary', this.baseReport);
      formData.append('portfolioAnalyst', this.summaryReport);
      if (this.shorts) {
        formData.append('lastYearShorts', this.shorts);
      }

      if (this.longs) {
        formData.append('lastYearLongs', this.longs);
      }

      if (this.note) {
        formData.append('userNote', this.note);
      }

      axios.post(`/taxes/calculation`, formData).then(
          (res) => {
            return new Promise((resolve, reject) => {
              this.taxesExtractionTaskId = res.data;
              const storePdfReport = () => {
                  axios.get(`/taxes/PDF?id=` + this.taxesExtractionTaskId, {responseType: "arraybuffer"}).then(
                      (res) => {
                        if (res.status === 200) {
                          this.pdfReport = new Blob([res.data], {type: "application/pdf"});
                          resolve();
                        }
                        reject();
                      }
                  ).catch(handleErr)
              };
              const fetchPdfStatus = () => {
                axios.get(`taxes/calculation?id=${this.taxesExtractionTaskId}`)
                  .then(res => {
                    if (res.status === 202) {
                      setTimeout(fetchPdfStatus, 1000)
                    } else if (res.status === 200){
                      storePdfReport();
                    } else {
                      reject()
                    }
                  })
                  .catch(reject);
              };

              fetchPdfStatus();
            });
          }).catch(handleErr).finally(
          () => {
            this.isCalculating = false;
          }
      );
    },
    downloadPdf() {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.pdfReport);
      link.download = "tax_report.pdf";
      link.click();
    },

  },
  mounted() {
    this.availableYears;
    this.fetchAvailableTaxYears().then(() => {
      this.report_year = this.availableYears[0];
      this.note = `Tax report ${this.availableYears[0]}`;
    });
    this.fetchTaxHistory();
  },
  computed: {
    ...mapGetters("stockpickingLabStore", {
      availableYears: "getAvailableTaxYears",
      taxHistory: "getTaxHistory"
    }),
    pdfURL() {
      if (this.pdfReport) {
        return window.URL.createObjectURL(this.pdfReport);
      } else {
        return null;
      }
    },
    isLastYearValuesDisabled() {
      return this.multiSwitchValue.id === "predefined";
    },
    predefinedOptions() {
      return this.taxHistory.map(item =>  item.user_note);
    },
    hasPredefinedOptions() {
      return this.taxHistory && this.taxHistory.length > 0
    }
  },
  watch: {
    selectedPredefinedSettings(val) {
      const selectedItem =  this.taxHistory.find(item => item.user_note === val);
      this.shorts = selectedItem.open_shorts;
      this.longs = selectedItem.open_longs;
      this.note = selectedItem.user_note;
    },
    multiSwitchValue() {
      this.shorts = 0;
      this.longs = 0;
      this.note = "";
      this.selectedPredefinedSettings = null;
    }
  },
}
</script>

<style lang="scss" scoped>

.status li {
  color: #32325d;
}
.pdf-preview {
  /*max-height: 400px;*/
  overflow: scroll;
  border-radius: 4px;
  margin-top: toRem(15);
  border: 1px solid $separator;
  &::-webkit-scrollbar {
   display: none;
 }
}

.manual {
  margin-top: 2rem;
}

.input-group-append {
  display: block;
}

.custom-form-item {
  font-size: 0.75rem;
}

.input-group-text {
  font-size: 0.75rem;
}

.mandatory {
  color: red;
  font-weight: 900;
}

#tax-form {
  margin-right: 2rem;
}

#main-content {
  padding: 25px;
  margin-top: 52px;
}

#wrapper {
  display: flex;
  height: calc(100vh - 52px);
}
@media only screen and (max-width: 800px){
  #wrapper{
    #main-content{
      margin-top: toRem(75);
    }
  }
}
</style>