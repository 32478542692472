import { render, staticRenderFns } from "./StockTaxes.vue?vue&type=template&id=d6f7deda&scoped=true&"
import script from "./StockTaxes.vue?vue&type=script&lang=js&"
export * from "./StockTaxes.vue?vue&type=script&lang=js&"
import style0 from "./StockTaxes.vue?vue&type=style&index=0&id=d6f7deda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6f7deda",
  null
  
)

export default component.exports