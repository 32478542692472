<template>
  <div
    class="selectbox-root"
    :class="{ error: hasError, focused: isFocused, disabled }"
    :data-cy="dataCy"
  >
    <label>{{ name }}</label>
    <v-select
      @search="$emit('search', $event)"
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event)"
      :options="options"
      @search:focus="onFocus"
      @search:blur="onBlur"
      :disabled="disabled"
      :label="label"
      :name="name"
      :loading="loading"
      :dropdown-should-open="() => focused"
      @close="onClose"
      @open="onOpen"
    >
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          <span class="search-info">
            <em>{{ search }}</em> {{ $t("validations.notFound") }}</span
          >
        </template>
        <em class="opacity" v-else
          ><span class="search-info">{{
            $t("validations.noOptions")
          }}</span></em
        >
      </template>
      <template v-slot:option="option" v-if="reportType">
        <span class="flex align-center">
          <report-type :type="option.value" />
          {{ option.name }}
        </span>
      </template>
      <template #selected-option="{ fullName }" v-if="formatOption">
        {{ fullName }}
      </template>
      <template #selected-option="{ name, value }" v-else-if="reportType">
        <span class="flex align-center">
          <report-type :type="value" />
          {{ name }}
        </span>
      </template>
      <template #list-footer v-if="!isLastPage && isAssetsPicker">
        <li ref="load" class="spinner-li">
          <b-spinner small />
        </li>
      </template>
    </v-select>
    <p :class="{ errorMessage: hasError }" class="inputHelper">
      {{ helperText }}
    </p>
  </div>
</template>

<script>
import ReportType from "../tables/tableComponents/reportComponents/ReportType.vue";
export default {
  name: "ANP-Dropdown",
  components: {
    ReportType,
  },
  props: {
    value: {
      type: [String, Object],
      description: "Value of the selectbox",
    },
    options: {
      type: Array,
      description: "Options for the selection",
    },
    isLastPage: {
      type: Boolean,
      description:
        "Whether this is last page of assets (irelevant for non paginated resources)",
    },
    hasError: {
      type: Boolean,
      description: "Whether the selectbox is wrong",
    },
    helperText: {
      type: String,
      description: "Additional information for the selectbox state/value",
      default: "",
    },
    isAssetsPicker: {
      type: Boolean,
      description: "Whether it is special type of asset picker",
    },
    disabled: {
      type: Boolean,
      description: "Whether the selectbox t is disabled",
    },
    label: {
      type: String,
      description: "Name of the attribute to display in selections",
    },
    name: {
      type: String,
      description: "Name of the label",
    },
    dataCy: {
      type: String,
      description: "Cypress selector",
    },
    formatOption: {
      type: Boolean,
      default: false,
    },
    reportType: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      focused: false,
      observer: null,
      scrollTop: 0,
      scrollEl: null,
    };
  },
  methods: {
    onBlur() {
      (this.focused = false), this.$emit("blur");
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus");
    },
    onClose() {
      if (this.isAssetsPicker) {
        this.observer.disconnect();
      }
    },
    onOpen() {
      if (this.isAssetsPicker) {
        this.observer = new IntersectionObserver(this.handleIntersection);
        setTimeout(() => {
          this.observer.observe(this.$refs.load);
        }, 100);
      }
    },
    handleIntersection([{ isIntersecting, target }]) {
      if (isIntersecting && this.isAssetsPicker) {
        const ul = target.offsetParent;
        const scrollTop = target ? target.offsetParent.scrollTop : this.scrollTop;
        if (!this.isLastPage && !this.loading) {
          this.$emit("loadNextPage");
          this.scrollTop = scrollTop;
          this.scrollEl = ul;
        }
      }
    },
    async handleOptionsChanged(data, oldData) {
      if (this.isAssetsPicker) {
        if (data.length > oldData.length && oldData.length > 0) {
          await this.$nextTick();
          this.scrollEl.scrollTop = this.scrollTop;
        } else if (oldData.length === 0 && data.length > 0) {
          await this.$nextTick();
          this.observer.observe(this.$refs.load);
        }
      }
    },
  },
  computed: {
    isFocused() {
      if (this.value && Object.keys(this.value).includes("code")) {
        return this.value.code;
      } else {
        return this.focused || this.value;
      }
    },
  },
  watch: {
    options: "handleOptionsChanged",
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.spinner-li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-type {
  height: 20px;
  width: 20px;
  min-width: 20px;
}
</style>