<template>
  <div class="multi-switch-root"  :class="this.className">
    <div
      class="multi-switch-item"
      :class="{ active: option.id === value.id, notAllowed: option.maxFrame && option.maxFrame < frame }"
      v-for="option in options"
      :key="option.id"
      :frame="frame"
      @click="$emit('input', option)"
    >
      {{ option.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Multi-switch",
  props: {
    options: {
      type: Array,
    },
    value: {
      type: Object,
    },
    className: {
      type: String
    },
    frame: {
      type: Number,
    }
  },
};
</script>

<style lang="scss" scoped>
.multi-switch-root {
  padding: toRem(2);
  border: 1px solid $blueBorders;
  background-color: white;
  border-radius: $inputRadius;
  display: flex;
  justify-content: stretch;
  &.small{
    .multi-switch-item{
      padding: 0.2rem 0.5rem;
    }
  }
  &.box-border{
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: toRem(5);
  }
  &.no-border{
    border: none;
    .multi-switch-item{
      &:hover{
        border: 1px solid $blueBorders;
        padding: 0.1rem 0.4rem;
      }
    }
  }
  .multi-switch-item {
    border-radius: $inputRadius;
    text-align: "center";
    padding: toRem(6) toRem(16);
    flex-grow: 1;
    cursor: pointer;
    &.active {
      background-color: $blueBackground;
    }
    &.notAllowed {
    cursor: not-allowed;
    pointer-events: none;
    }
  }
}
</style>